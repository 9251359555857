import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
// import ReactPlayer from 'react-player';

const Banner = (props) => {
    const { forceShow = true } = props;
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + ' buttonsGallery"></span>';
        }
    };
    return forceShow ? (
        <section className="ld--wrap-gallery">
            <div className="ld--card">
                <div className="ld--title">Galería</div>
                <div
                    id="newIndicators"
                    className="carousel slide"
                    // style={{ height: '382px', overflow: 'hidden' }}
                >
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        pagination={pagination}
                        navigation={true}
                        // autoplay={{ delay: 5000, disableOnInteraction: true }}
                        modules={[Autoplay, Pagination, Navigation]}
                    >
                        <SwiperSlide>
                            <img
                                className="imgGal"
                                src={`${process.env.REACT_APP_URL_IMAGES}banner-desktop.jpg`}
                                alt="1"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    ) : null;
};

export default Banner;
